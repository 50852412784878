<template>
  <b-modal
    centered
    id="deliveryCountryNotFound"
    hide-header
    hide-footer
    size="lg"
    @hidden="clearData"
    dialog-class="registerCountryNotFound"
  >
    <template #default="{ hide }">
      <div class="d-flex">
        <h5>
          {{ $t("Modal.CountryNotFound.Title") }}
        </h5>
        <b-button class="p-1 h-25" @click="$bvModal.hide('deliveryCountryNotFound')">
          <b-icon icon="x" />
        </b-button>
      </div>
      <b-form>
          <p class="description">
            {{ $t("Modal.CountryNotFound.Description") }}
          </p>
          <b-form-group class="w-100 field">
            <label class="form-label">
              {{ $t("Modal.CountryNotFound.FromCountry") }}
            </label>
            <multiselect
              v-model="country"
              label="Name"
              track-by="Code"
              :options="countries"
              :multiple="true"
              :placeholder="$t('Modal.NoCountry.CountrySelectorPlaceholder')"
            />
          </b-form-group>
          <b-form-group class="w-100 field">
            <label class="form-label">
              {{ $t("Modal.CountryNotFound.Name") }}
            </label>

            <b-input-group>
              <b-form-input type="text" v-model="name" class="match-input-size input-pills" />
            </b-input-group>
          </b-form-group>
          <b-form-group class="w-100 field">
            <label class="form-label">
              {{ $t("signIn.label.emailAddress") }}
            </label>
            <b-input-group>
              <b-form-input
                type="email"
                class="match-input-size input-pills"
                v-model="email"
                :state="email ? isEmail : null"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group cols="12" class="field">
            <label class="form-label">
              {{ $t("signIn.label.phoneNumber") }}
            </label>
            <b-input-group class="m-0">
              <VuePhoneNumberInput
                show-code-on-list
                class="p-0"
                v-model.trim="mobile"
                :default-country-code="$store.getters.country.Code ? $store.getters.country.Code : 'CA'"
                noExample
                @update="setMobileNumber($event)"
                :translations="{
                          countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                          countrySelectorError: $t('Contact.countrySelectorError'),
                          phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                          example: $t('Contact.example'),
                        }"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group class="mt-4 field">
            <b-button
              variant="primary"
              class="input-pills w-100 sendFormButton"
              @click="send()"
              :disabled="isFormValid"
            >
              {{ $t("Modal.CountryNotFound.Submit") }}
            </b-button>
          </b-form-group>
      </b-form>
      <div class="question-part mt-4">
        <p>
          {{ $t("Modal.NoCountry.Questions") }}
          <b-link :to="{ name: 'Contact-Us' }" @click="hide()">
            {{ $t("Contact.ContactUs.ContactUs") }}
          </b-link>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import phoneMixin from "../mixins/phoneMixin";

export default {
  components: {
    VuePhoneNumberInput
  },
  mixins: [phoneMixin],
  computed: {
    ...mapGetters(["countries"]),
    isEmail() {
      if (this.email) {
        if (this.emailFormat.test(this.email)) {
          return true;
        } else return false;
      } else return false;
    },
    isFormValid() {
      return !this.name || !this.email || !this.country || !this.isEmail || (!this.mobilePayload || !this.mobilePayload.isValid);
    },
  },
  methods: {
    async send() {
      try {
        await axios.post("Basic/CantFindCountryBuyer", {
          CountryCodeList: this.country.map((item) => item.Code),
          CountryCode: this.$store.getters.country.Code,
          Name: this.name,
          Email: this.email,
          Mobile: this.mobilePayload.formattedNumber
        });
        this.clearData();
        this.$bvModal.hide("deliveryCountryNotFound");
        this.$store.dispatch("showToast", {
          content: this.$t("global.message.formIsSent"),
          type: "success"
        });
      } catch (error) {
        console.log(error);
      }
    },
    clearData() {
      Object.assign(this.$data, {
        value: null,
        options: ["list", "of", "options"],
        name: null,
        email: null,
        emailFormat: /\S+@\S+\.\S+/,
        country: null,
        searchCountry: "",
        mobile: null
      });
    },
  },
  data() {
    return {
      value: null,
      options: ["list", "of", "options"],
      name: null,
      email: null,
      emailFormat: /\S+@\S+\.\S+/,
      country: [],
      searchCountry: "",
      mobile: null,
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style type="text/css">
/* phone input styles */
.input-phone-number {
  height:100%!important;
}
.countrySelector .btn {
  border-radius: 50px !important;
  border: 1px solid #ced4da !important;
  height: 38px !important;
  text-align: left;
  line-height: 1 !important;
  padding: 0 0.75rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.countrySelector .btn:hover,
.countrySelector.show .btn {
  background-color: #fff;
  color: #000;
  border-color: #99c0f8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(34 119 239 / 25%);
}
</style>
<style lang="scss">
.match-input-size {
  padding:20px!important;
}
  .input-tel {
    input {
      height:100%!important;
      border-radius: 35px !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .country-selector {
    border: none !important;
    position: relative;
    width: 100px;
    height:100%!important;
    svg {
      display: none !important;
    }
    input {
      display: flex;
      align-items: center;
      padding: 0 0 0 48px !important;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 33px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: #d0d0d0;
    }
  }
  .country-selector__input {
    height: 100%;
  }
  .vue-phone-number-input {
    border-radius: 35px !important;
    height: 48px !important;
    border: 1px solid #ccc !important;
    input {
      box-shadow: none !important;
      outline: none !important;
      border: none !important;
      background: none !important;
      height: 48px;
    }
  }
  .country-selector__label {
    display: none !important;
  }
  .country-selector__country-flag {
    top: 18px !important;
    left: 20px !important;
  }
/* end phone input styles */
.registerCountryNotFound {
  max-width: 580px !important;
  .modal-content {
    padding: 1rem 2rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
  }
  .modal-header {
    padding: 1rem 0;
    margin-bottom: 1rem;
    h1 {
      margin: 0;
    }
    .close {
      width: 24px;
      height: 24px;
      background: #dbdbdb;
      border-radius: 100%;
      padding: 0 !important;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      margin: 0;
      svg {
        color: #777;
      }
    }
  }
  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #404040;
    margin-bottom: 1.5rem;
  }
  .question-part {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #404040;
    text-align: center;
  }
  .sendFormButton {
    height: 2.75rem;
    background-color: $light-blue;
    &:disabled {
      &:hover {
        background-color: $light-blue;
        border-color: $light-blue;
      }
    }
    &:hover {
      background-color: #00559d;
      border-color: #004e90;
    }
  }
  .field {
    label {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0.5rem;
    }
    input{
      &.input-tel__input {
        border-right: 1px solid #ccc !important;
        border-radius: 0 38px 38px 0 !important;
      }
    }
  }
  .multiselect {
    border-radius: 50px !important;
    .multiselect__tags {
      padding: 0.5rem 40px 0 1rem !important;
      min-height: 30px !important;
      border-radius: 50px !important;
      border: 1px solid $half-dark !important;
    }
    .multiselect__tag {
      border-radius: 50px !important;
    }
    &--active {
      .multiselect__tags {
        height: auto;
        border-radius: 1rem !important;
      }
      .multiselect__content-wrapper {
        margin-top: 0.25rem;
        border: 1px solid $half-dark !important;
        border-radius: 1rem !important;
      }
    }
  }
}
</style>
